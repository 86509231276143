<template>
  <list
    :reference="reference"
    :filters="filters"
    :sorters="sorters"
    :active-sort="activeSort"
  >
    <template slot-scope="props">
      <list-card
        :title="props.item.site.fqdn"
        :subtitle-dark="props.item.product.name"
        :subtitle-grey="getContractDetails(props.item)"
        :label="`${props.item.status}`"
        :timestamp="props.item.dateCreated.toDate()"
        :route="{ path: `/admin/contracts/${props.item._id}` }"
      />
    </template>
  </list>
</template>

<script>
import { statuses } from "@/data/contract";
import { collection } from "@firebase/firestore";
export default {
  name: "AdminContracts",
  data() {
    return {
      reference: collection(this.$firestore, "contracts"),
      filters: [
        {
          key: "status",
          label: "Status",
          options: this.$_.map(statuses, status => status),
          type: "select-radio"
        },
        {
          key: "site.fqdn",
          label: "Site",
          type: "string"
        }
      ],
      sorters: [
        {
          field: "status",
          label: "Status"
        },
        {
          field: "dateCreated",
          label: "Date Created"
        },
        {
          field: "dateNextDue",
          label: "Date Next Due"
        }
      ],
      activeSort: {
        field: "dateCreated",
        direction: "desc"
      }
    };
  },
  methods: {
    getContractDetails(contract) {
      if (contract.status === -1)
        return `Lapsed ${this.$moment(contract.dateNextDue.toDate()).from()}`;
      if (contract.status === 0)
        return `Expires ${this.$moment(contract.dateNextDue.toDate()).from()}`;
      if (contract.status === 1)
        return `Renews ${this.$moment(contract.dateNextDue.toDate()).from()}`;
      if (contract.status === 2)
        return `Payment due ${this.$moment(
          contract.dateNextDue.toDate()
        ).from()}`;
    },
    getDateNextDue(contract) {
      if (contract.status > 0)
        return `Due ${this.$moment(contract.dateNextDue.toDate()).format(
          "Do MMM, YYYY"
        )}`;
      return "–";
    }
  }
};
</script>
